import * as PIXI from "pixi.js";
import { Container } from "pixi.js";
import { Seat } from "./seat";

export class Rubberband {
  startMousePoint?: PIXI.Point;
  currentMousePoint?: PIXI.Point;
  mainObject: Container;
  dragging = 0;
  selection: any;
  containsSeatsCallback: (seats: Seat[]) => void;

  constructor(obj: Container, containsSeatsCallback: (seats: Seat[]) => void) {
    this.mainObject = obj;
    this.mainObject
      .on("pointerdown", this.onDragStart.bind(this))
      .on("pointerup", this.onDragEnd.bind(this))
      .on("pointerupoutside", this.onDragEnd.bind(this))
      .on("pointermove", this.onDragMove.bind(this));
    this.containsSeatsCallback = containsSeatsCallback;
  }

  private onDragStart(event: PIXI.FederatedPointerEvent) {
    this.dragging = 1;
    this.startMousePoint = event.getLocalPosition(this.mainObject);
    event.stopPropagation();
  }

  private onDragEnd(event: PIXI.FederatedPointerEvent) {
    if (!this.dragging) return;
    this.dragging = 0;
    const seats = [];
    if (this.selection) {
      const bounds = this.selection.geometry.bounds;
      if (this.mainObject.children) {
        for (const sb of this.mainObject.children) {
          if (sb.children) {
            for (const seat of sb.children) {
              if (seat instanceof Seat) {
                const seatPosition = seat.toGlobal(seat);
                const seatCenterX = seatPosition.x + seat.width / 2;
                const seatCenterY = seatPosition.y + seat.height / 2;
                if (
                  seatCenterX < bounds.maxX &&
                  seatCenterX > bounds.minX &&
                  seatCenterY < bounds.maxY &&
                  seatCenterY > bounds.minY
                ) {
                  seats.push(seat);
                }
              }
            }
          }
        }
      }
      this.mainObject.removeChild(this.selection);
      this.selection = null;
    }
    this.containsSeatsCallback(seats);
  }

  private onDragMove(event: PIXI.FederatedPointerEvent) {
    if (!this.dragging || !this.startMousePoint || !this.mainObject) return;
    this.currentMousePoint = event.getLocalPosition(this.mainObject);
    const x = Math.min(this.startMousePoint.x, this.currentMousePoint.x);
    const y = Math.min(this.startMousePoint.y, this.currentMousePoint.y);
    const width =
      Math.max(this.startMousePoint.x, this.currentMousePoint.x) - x;
    const height =
      Math.max(this.startMousePoint.y, this.currentMousePoint.y) - y;

    if (this.selection) {
      this.mainObject.removeChild(this.selection);
      this.selection = null;
    }
    this.selection = new PIXI.Graphics();
    this.selection.alpha = 0.2;
    this.selection.beginFill(0xff0000);
    this.mainObject.addChild(this.selection);
    this.selection.drawRect(x, y, width, height);
  }
}
